.wrapper {
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}

.innerWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
}
