.chatChoice {
  padding: 13px;
  box-sizing: content-box;
  border: 2px solid #005675;
  border-radius: 30px;
  max-width: 70%;
  margin: 10px 0 0;
  align-self: flex-end;
  background-color: #ffffff;
  font-size: 16px;
  font-family: 'Mulish', sans-serif;
  text-align: left;
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  transition: background-color 0.1s ease-in-out;
  line-height: 26px;
}

.chatChoice:hover {
  background-color: #005675;
  color: #ffffff;
}

.chatChoice:focus {
  outline: none;
}
