.wrapper {
  display: flex;
  max-width: 100vw;
  background-color: #ffffff;
  transition: background-color 0.1s ease-in-out;
  align-items: center;
  cursor: pointer;
  font-family: 'Mulish', sans-serif;
}

.chatOverview {
  margin: 8px 0 8px 32px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
}

.chatOverview.unread {
  margin-left: 0;
}

.chatOverviewUser {
  font-size: 13px;
  font-weight: 700;
  color: #666666;
  margin: 4px 0;
}

.chatOverviewMessage {
  line-height: 24px;
  margin-bottom: 4px;
}

.chatOverview .chatOverviewUser,
.chatOverview .chatOverviewMessage {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chatOverview.open .chatOverviewMessage {
  white-space: normal;
}

.infoAndOptions {
  justify-self: flex-end;
  margin: 0 15px;
  min-width: 32px;
  text-align: right;
}

.time {
  font-size: 13px;
  color: #666666;
  line-height: 24px;
  height: 24px;
}

.wrapper:hover {
  background-color: #e0e0e0;
}
