.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wrapperTop {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.nav {
  background-color: #ffffff;
  padding: 3px 0 3px 6px;
  width: calc(100% - 6px);
}

.chat {
  padding: 20px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.wrapperBottom {
  padding: 20px;
  padding-top: 24px;
  display: flex;
  align-items: flex-start;
  align-items: center;
}

.disclaimer {
  padding: 0 10px;
}

.sendMessageInput {
  width: 100%;
}

.sendMessageBtn {
  margin-top: 10px;
  margin-left: 20px;
}

.endOfChat {
  font-family: 'Mulish', sans-serif;
  text-align: center;
  font-size: 13px;
  color: #666666;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #cccccc;
}
