.wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #c0c0c0;
  border-radius: 15px;
  margin-bottom: 10px;

  /* @NOTE: prevents blue highlight onTouch for mobile phones */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.itemWrapper {
  display: flex;
  padding: 20px;
  align-items: center;
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  justify-content: space-between;
  cursor: pointer;
}

.left {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 10px;
}

.right {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.icon {
  /* @NOTE: width and height are added because when the accordian has a note,
     it messes with the default icon width of 24px; so we're constraining it here. */
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

.icon.active {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.labelWrapper,
.noteWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
}

.labelWrapper.withIcon {
  margin-left: 10px;
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.sublabel {
  font-size: 12px;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noteWrapper {
  align-items: flex-end;
  justify-content: flex-end;
}

.note {
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  visibility: collapse;
  opacity: 0;
  height: 0;
}

.content.active {
  visibility: visible;
  opacity: 1;
  height: auto;
}

.content.active.padded {
  padding: 20px;
}
