.wrapper {
  display: flex;
  height: 58px;
  background-color: #ffffff;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: 'Mulish', sans-serif;
  color: #292f36;
  margin-left: 22px;
}

.button {
  margin-right: 7px;
}
