.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.userChats {
  flex: 1;
  overflow-y: scroll;
}

.chatOverview {
  margin-top: 4px;
  box-shadow: 0 2px 0 #e0e0e0;
}
