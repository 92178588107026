.outerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wrapper {
  /* @NOTE: This is the width that Google Messages uses */
  max-width: calc(70% + 40px);
  font-family: 'Mulish', sans-serif;
  line-height: 26px;
  margin: 10px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.wrapper.other,
.wrapper.bot {
  align-self: flex-start;
}

.wrapper.self {
  align-self: flex-end;
}

.wrapper.event {
  align-self: center;
}

.text {
  padding: 15px;
  border-radius: 30px;
  font-size: 16px;
  width: fit-content;
  word-break: break-word;
}

.wrapper.other .text,
.wrapper.bot .text {
  background-color: #f5f5f5;
  align-self: flex-start;
}

.wrapper.event .text {
  background-color: #ba2063;
  color: #ffffff;
}

.wrapper.self .text {
  background-color: #005675;
  color: #ffffff;
}

.info {
  font-size: 13px;
  color: #666666;
  margin: 0 16px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 32px);
}

.sender {
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.date {
  white-space: nowrap;
  margin-left: 10px;
}
