.card {
  background-color: #ffffff;
  border-radius: 40px;
  padding: 20px;
  width: 100%;
  margin: 0 -20px 32px;
  font-family: 'Mulish', sans-serif;
}

.cardForeground {
  border: 1px solid #c0c0c0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card h3 {
  margin: 0 10px;
  font-weight: 700;
  font-size: 20px;
}

.card table {
  margin: 10px;
  width: calc(100% - 40px);
}

.card tr {
  padding: 0;
}

.card td {
  padding: 10px 0;
  font-size: 16px;
}

.key {
  padding-right: 20px;
  font-weight: 700;
}

.value {
  word-break: break-all;
  min-width: 100px;
}

.requestedCallType {
  width: 273px;
  display: flex;
  padding: 23px;
  justify-content: center;
  margin-bottom: 20px;
}

.profileCardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
}
