.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
}

.noPadding {
  padding: 0;
}

.item {
  width: 100%;
}
