.wrapper {
  padding-top: 100px;
}

.wrapper.mobile {
  padding-top: 60px;
}

.innerWrapper {
  overflow: scroll;
  background-color: var(--light-gray);
  min-height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper.mobile .innerWrapper {
  min-height: calc(100vh - 60px);
}

.pageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  padding: 40px 24px;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
}

.header {
  margin-bottom: 40px;
}
