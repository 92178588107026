.wrapper {
  font-family: 'Mulish', sans-serif;
  display: flex;
  max-width: 100vw;
  background-color: #ffffff;
  transition: background-color 0.1s ease-in-out;
  align-items: center;
  cursor: pointer;
}

.notification {
  margin: 8px 0 8px 32px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
}

.notification.unread {
  margin-left: 10px;
}

.notificationType {
  font-size: 13px;
  font-weight: 700;
  color: #666666;
  margin: 4px 0;
}

.notificationMessage {
  line-height: 24px;
  margin-bottom: 4px;
}

.notification .notificationType,
.notification .notificationMessage {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notification.open .notificationMessage {
  white-space: normal;
}

.infoAndOptions {
  justify-self: flex-end;
  margin: 0 15px;
  min-width: 32px;
  text-align: right;
}

.time {
  font-size: 13px;
  color: #666666;
  line-height: 24px;
  height: 24px;
}

/* @SOURCE: https://medium.com/@ferie/detect-a-touch-device-with-only-css-9f8e30fa1134 */
@media (hover: hover) {
  .wrapper:hover {
    background-color: #e0e0e0;
  }

  .wrapper:hover .time {
    display: none;
  }

  .wrapper:hover .archive {
    display: block;
  }
}

.archive {
  display: none;
}
