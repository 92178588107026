.wrapper {
  position: fixed;
  display: flex;
  height: 100px;
  padding: 0 20px;
  width: calc(100vw - (2 * 20px));
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  top: 0;
  transition: all 0.1s ease-in-out;
  z-index: 100;
}

.wrapper.mobile {
  height: 60px;
}
